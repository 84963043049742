<template>
  <div class="mainColumn">

    <div class="topElement">
      <div class="titleFlex">
        <div :class="titleCaptionClass">
          {{ updateStatusOfTheDayData?.titleCaption }}
        </div>

        <div :class="titleCenterElementClass">
          {{ updateStatusOfTheDayData?.title }}
        </div>
      </div>

    </div>

    <ProjectAlert :visibleSuccess="showSuccess" :visibleError="showError" />

    <SquareUpdateStatusOfTheDay class="squareStyle" @server-success="serverSuccess" @server-error="serverError"
      v-bind:update-status-of-the-day-data="updateStatusOfTheDayData" />

    <BackButtonUpdateStatus v-bind:update-status-of-the-day-data="updateStatusOfTheDayData" class="backButtonStyle" />

  </div>
</template>

<script>
import SquareUpdateStatusOfTheDay from "@/components/ui/UpdateStatusOfTheDayUI/SquareUpdateStatusOfTheDay";
import ProjectAlert from "@/components/ui/alert/ProjectAlert";
import BackButtonUpdateStatus from "@/components/ui/UpdateStatusOfTheDayUI/BackButtonUpdateStatus";
import axios from "axios";
import router from "@/main";

export default {
  name: "UpdateStatusOfTheDay",
  components: { BackButtonUpdateStatus, ProjectAlert, SquareUpdateStatusOfTheDay },

  data() {
    return {
      updateStatusOfTheDayData: null,
      showSuccess: false,
      showError: false,

      titleCenterElementClass: null,
      titleCaptionClass: null,
    }
  },

  mounted() {
    var initDataBase64 = btoa(window.Telegram.WebApp.initData);

    if (window.Telegram.WebApp.colorScheme === 'dark') {
      this.titleCenterElementClass = "titleCenterElementDark";
      this.titleCaptionClass = 'titleCaptionDark';
    } else {
      this.titleCenterElementClass = "titleCenterElementLight";
      this.titleCaptionClass = 'titleCaptionLight';
    }

    axios
      .get(`${this.hostname}/statusOfTheDay/getUpdateStatusOfTheDayData?page=${this.$route.params.page}&initData=${initDataBase64}`)
      .then(response => (this.updateStatusOfTheDayData = response.data))
      .catch(error => {
        if (error.response && error.response.status === 401) {
          router.push("/unathorized");
        } else {
          router.push('/serverErrorPage')
        }
      });
  },

  methods: {
    serverSuccess() {
      this.showSuccess = true;

      setTimeout(() => {
        router.push("/statusOfTheDay");
      }, 2750);
    },

    serverError() {
      this.showError = true;

      setTimeout(() => {
        router.push("/statusOfTheDay");
      }, 2750);
    }

  }
}
</script>

<style scoped>
.mainColumn {
  height: calc(var(--tg-viewport-stable-height) - 4vh);
  display: flex;
  flex-direction: column;
}

.topElement {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.titleFlex {
  justify-content: flex-end;
  /*height: 5vh;*/
  align-self: center;
}

.titleCenterElementDark {
  margin: auto;
  font-family: Garamond, serif;
  /*font-family: Windsor, serif;*/
  font-size: 4.5vh;
  color: var(--text-foreground-dark);
  text-align: center;
}

.titleCenterElementLight {
  margin: auto;
  font-family: Garamond, serif;
  /*font-family: Windsor, serif;*/
  font-size: 4.5vh;
  color: var(--text-foreground-light);
  text-align: center;
  filter: brightness(0.75);
}

.titleCaptionDark {
  text-align: center;
  font-family: Sonatina, serif;
  font-size: 9vw;
  color: var(--field-foreground-dark);
  filter: brightness(1.15);
}

.titleCaptionLight {
  text-align: center;
  font-family: Sonatina, serif;
  font-size: 9vw;
  color: var(--field-foreground-light);
  filter: brightness(1.15);
}

.squareStyle {
  position: absolute;
  margin-left: 30vw;
  margin-top: 30vh;
}

.backButtonStyle {
  position: absolute;
  margin-top: 85vh;
}
</style>