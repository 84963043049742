import { createWebHistory, createRouter } from 'vue-router';

import { createApp } from 'vue';
import App from './App';
import Konva from 'konva';
import axios from 'axios';

import ExamplePage1 from './components/ui/ExamplePage1/ExamplePage1.vue';
import ExamplePage2 from './components/ui/ExamplePage2/ExamplePage2.vue';
import ExamplePage3 from './components/ui/ExamplePage3/ExamplePage3.vue';
import StatusOfTheDay from '@/StatusOfTheDay';
import UpdateStatusOfTheDay from '@/UpdateStatusOfTheDay';
import UnathorizedPage from '@/UnathorizedPage';
import ServerErrorPage from '@/ServerErrorPage';
import VisualResultsPage from './components/ui/ResultsPageUI/VisualResultsPage.vue';
//import SuccessesView from './components/ui/SuccessesPage/SuccessesView.vue';

var routes = [
    {
        path: '/',
        name: 'App',
        component: App,
    },
    {
        path: '/examplePage1',
        name: 'ExamplePage1',
        component: ExamplePage1,
    },
    {
        path: '/examplePage2',
        name: 'ExamplePage2',
        component: ExamplePage2,
    },
    {
        path: '/examplePage3',
        name: 'ExamplePage3',
        component: ExamplePage3,
    },
    {
        path: '/statusOfTheDay',
        name: 'StatusOfTheDay',
        component: StatusOfTheDay,
    },
    {
        path: '/updateStatusOfTheDay/:page',
        name: 'UpdateStatusOfTheDay',
        component: UpdateStatusOfTheDay,
    },
    {
        path: '/unathorized',
        name: 'UnathorizedPage',
        component: UnathorizedPage,
    },
    {
        path: '/serverErrorPage',
        name: 'ServerErrorPage',
        component: ServerErrorPage,
    },
    {

        path: '/visualResultsPage',
        name: 'VisualResultsPage',
        component: VisualResultsPage,

        /*
        path: "/visualResultsPage/:periodData",
        name: "VisualResultsPage",
        component: VisualResultsPage,
        props: route => ({
           periodData: JSON.parse(route.params.periodData),
        }),
        */
    },
    /*
    {
        path: '/successesPage',
        name: 'SuccessesPage',
        component: SuccessesView,
    }
        */
]

var router = createRouter({
    history: createWebHistory(),
    routes
})

export default router;

var app = createApp(App);

/*
app.config.errorHandler = async (error, vm, info) => {
    await axios
        .post(`https://mygreatday.ru:88/frontLogger/saveError?error=${error}&info=${info}`)
        .catch(error => {
            console.log(error);
        });
};
*/

/*
components.forEach(component => {
    app.component(component.name, component);
});
*/
app.use(router);
app.use(Konva);

app.mount('#app');

app.config.globalProperties.app = app;

app.config.globalProperties.hostname = "https://mygreatday.ru:88";
//app.config.globalProperties.hostname = 'http://localhost:88';
app.config.globalProperties.serverErrorCaption = 'Server error, try again later.';

