<template>
  <div class="field2ColumnFlex">
    <span class="field2Caption">{{ examplePage2Data?.moneyFieldLabel }}</span>
    <img src="../../../../res/icons/tired.png" class="field2Img" />
    <span :class="field2ExplanationClass">{{ examplePage2Data?.moneySmileCaption }}</span>
  </div>
</template>

<script>
export default {
  name: "MoneyExamplePage2",
  props: ['examplePage2Data'],

  data() {
    return {
      field2ExplanationClass: null,
    }
  },

  mounted() {
    if(window.Telegram.WebApp.colorScheme === 'dark') {
      this.field2ExplanationClass = "field2ExplanationDark";
    } else {
      this.field2ExplanationClass = "field2ExplanationLight";
    }
  }
}
</script>

<style scoped>
.field2ColumnFlex {
  display: flex;
  flex-direction: column;
}

.field2Caption {
  justify-content: flex-start;
  font-family: Sonatina, serif;
  font-size: 9vw;
  color: var(--field-foreground-light);
  filter: brightness(1.15);
}

.field2Img {
  width: 12.75vw;
  height: 12.75vw;
  align-self: center;
}

.field2ExplanationDark {
  justify-content: flex-end;
  font-family: Garamond, serif;
  font-size: 6vw;
  color: var(--text-foreground-dark);
}

.field2ExplanationLight {
  justify-content: flex-end;
  font-family: Garamond, serif;
  font-size: 6vw;
  color: var(--text-foreground-light);
  filter: brightness(0.75);
}
</style>