<template>
  <div class="mainColumn">

    <div class="topElement">
        <div class="navigationBar">
          <div class="navigationCenterElement">
            <div class="navigationCheckBoxes">
              <img :src="firstCheckBox" class="prjCheckBox"/>
              <img :src="nextArrow" class="prjCheckBox"/>
              <img :src="secondCheckBox" class="prjCheckBox"/>
              <img :src="nextArrow" class="prjCheckBox"/>
              <img :src="thirdCheckBox" class="prjCheckBox"/>
            </div>
          </div>
        </div>


      <div class="titleFlex">
        <div :class="titleCenterElementClass">
          {{ examplePage2Data?.title }}
        </div>
      </div>

    </div>

    <MainSquareExamplePage2 v-bind:example-page2-data="examplePage2Data"/>

    <PrevNextButtonsExamplePage2 v-bind:example-page2-data="examplePage2Data"/>
  </div>
</template>

<script>
import MainSquareExamplePage2 from "@/components/ui/ExamplePage2/MainSquareExamplePage2";
import PrevNextButtonsExamplePage2 from "@/components/ui/ExamplePage2/PrevNextButtonsExamplePage2";
import axios from "axios";
import router from "@/main";

export default {
  components: {PrevNextButtonsExamplePage2, MainSquareExamplePage2},

  data() {
    return {
      examplePage2Data: null,
      titleCenterElementClass: null,
      firstCheckBox: null,
      nextArrow: null,
      secondCheckBox: null,
      thirdCheckBox: null,
    }
  },

  mounted() {
    var initDataBase64 = btoa(window.Telegram.WebApp.initData);

    if(window.Telegram.WebApp.colorScheme === 'dark') {
      this.titleCenterElementClass = "titleCenterElementDark";
      this.firstCheckBox = "checked-dark.png";
      this.nextArrow = "next-dark.png";
      this.secondCheckBox = "checked-dark.png";
      this.thirdCheckBox = "unchecked-dark.png";
    } else {
      this.titleCenterElementClass = "titleCenterElementLight";
      this.firstCheckBox = "checked-light.png";
      this.nextArrow = "next-light.png";
      this.secondCheckBox = "checked-light.png";
      this.thirdCheckBox = "unchecked-light.png";
    }

    axios
        .get(`${this.hostname}/example?pageIndex=2&initData=${initDataBase64}`)
        .then(response => (this.examplePage2Data = response.data))
        .catch(error => {
          if (error.response && error.response.status === 401) {
            router.push("/unathorized");
          } else {
            router.push('/serverErrorPage')
          }
        });
  }
}

</script>

<style scoped>
.mainColumn {
  height: calc(var(--tg-viewport-stable-height) - 4vh);
  display: flex;
  flex-direction: column;
}

.topElement {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.navigationBar {
  height: 5vh;
  justify-content: flex-start;
  align-self: center;
}

.navigationCenterElement {
  color: dimgray;
}

.navigationCheckBoxes {
  display: flex;
  flex-direction: row;
}

.prjCheckBox {
  width: 5vw;
  height: 5vw;
  justify-content: space-between;
}

.titleFlex {
  justify-content: flex-end;
  /*height: 5vh;*/
  align-self: center;
}

.titleCenterElementDark {
  margin: auto;
  font-family: Garamond, serif;
  /*font-family: Windsor, serif;*/
  font-size: 4.5vh;
  color: var(--text-foreground-dark);
  text-align: center;
}


.titleCenterElementLight {
  margin: auto;
  font-family: Garamond, serif;
  /*font-family: Windsor, serif;*/
  font-size: 4.5vh;
  color: var(--text-foreground-light);
  text-align: center;
  filter: brightness(0.75);
}
</style>