<template>
  <div class="squareFlex">
    <div class="square">
      <div class="topGrid">

        <img src="../../../../res/icons/great.png" class="statusImg1" @click="clickGreatHandler" />
        <span :class="statusGreatExplanationClass" @click="clickGreatHandler">
          {{ updateStatusOfTheDayData?.greatCaption }}</span>

        <img src="../../../../res/icons/good.png" class="statusImg2" @click="clickGoodHandler" />
        <span :class="statusGoodExplanationClass" @click="clickGoodHandler">
          {{ updateStatusOfTheDayData?.goodCaption }}</span>

      </div>

      <div class="bottomGrid">
        <div class="tiredColumn">
          <img src="../../../../res/icons/tired.png" class="statusImg4" @click="clickTiredHandler" />
          <span :class="statusTiredExplanationClass" @click="clickTiredHandler">
            {{ updateStatusOfTheDayData?.tiredCaption }}</span>
        </div>

        <div class="freeColumn"></div>

        <div class="restColumn">
          <img src="../../../../res/icons/rest.png" class="statusImg3" @click="clickRestHandler" />
          <span :class="statusRestExplanationClass" @click="clickRestHandler">
            {{ updateStatusOfTheDayData?.restCaption }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import router from "@/main";

export default {
  name: "SquareUpdateStatusOfTheDay",
  props: ['updateStatusOfTheDayData'],

  data() {
    return {
      statusGreatExplanationClass: null,
      statusGoodExplanationClass: null,
      statusTiredExplanationClass: null,
      statusRestExplanationClass: null,
    }
  },

  mounted() {
    if (window.Telegram.WebApp.colorScheme === 'dark') {
      this.statusGreatExplanationClass = "statusGreatExplanationDark";
      this.statusGoodExplanationClass = "statusGoodExplanationDark";
      this.statusTiredExplanationClass = "statusTiredExplanationDark";
      this.statusRestExplanationClass = "statusRestExplanationDark";
    } else {
      this.statusGreatExplanationClass = "statusGreatExplanationLight";
      this.statusGoodExplanationClass = "statusGoodExplanationLight";
      this.statusTiredExplanationClass = "statusTiredExplanationLight";
      this.statusRestExplanationClass = "statusRestExplanationLight";
    }
  },

  methods: {
    async clickGreatHandler() {
      var page = this.$route.params.page;
      var status = 1;
      var initDataBase64 = btoa(window.Telegram.WebApp.initData);

      await axios
        .put(`${this.hostname}/statusOfTheDay/updateStatusOfTheDay?page=${page}&status=${status}&initData=${initDataBase64}`)
        .then(res => {
          if (res.status === 200) {
            this.$emit('server-success');
          } else {
            router.push('/serverErrorPage')
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            router.push("/unathorized");
          } else {
            router.push('/serverErrorPage')
          }
        });
    },

    async clickGoodHandler() {
      var page = this.$route.params.page;
      var status = 2;
      var initDataBase64 = btoa(window.Telegram.WebApp.initData);

      await axios
        .put(`${this.hostname}/statusOfTheDay/updateStatusOfTheDay?page=${page}&status=${status}&initData=${initDataBase64}`)
        .then(res => {
          if (res.status === 200) {
            this.$emit('server-success');
          } else {
            router.push('/serverErrorPage')
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            router.push("/unathorized");
          } else {
            router.push('/serverErrorPage')
          }
        });
    },

    async clickTiredHandler() {
      var page = this.$route.params.page;
      var status = 3;
      var initDataBase64 = btoa(window.Telegram.WebApp.initData);

      await axios
        .put(`${this.hostname}/statusOfTheDay/updateStatusOfTheDay?page=${page}&status=${status}&initData=${initDataBase64}`)
        .then(res => {
          if (res.status === 200) {
            this.$emit('server-success');
          } else {
            router.push('/serverErrorPage')
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            router.push("/unathorized");
          } else {
            router.push('/serverErrorPage')
          }
        });
    },

    async clickRestHandler() {
      var page = this.$route.params.page;
      var status = 4;
      var initDataBase64 = btoa(window.Telegram.WebApp.initData);

      await axios
        .put(`${this.hostname}/statusOfTheDay/updateStatusOfTheDay?page=${page}&status=${status}&initData=${initDataBase64}`)
        .then(res => {
          if (res.status === 200) {
            this.$emit('server-success');
          } else {
            router.push('/serverErrorPage')
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            router.push("/unathorized");
          } else {
            router.push('/serverErrorPage')
          }
        });
    }
  }
}
</script>

<style scoped>
.squareFlex {
  margin: auto;
}

.topGrid {
  display: grid;
  grid-template-columns: 12.75vw auto;
}

.bottomGrid {
  display: flex;
  /*grid-template-columns: 12.75vw auto;*/
  flex-direction: row;
  width: 96vw;
  margin-left: -30vw;
}

.squareHolder {
  /*
  position: absolute;
  top: 50%;
  left: 50%;
   */
}

.square {
  /*background: aquamarine;*/
  /*border: 5px solid red;*/
  text-align: center;

  /* mobile: */
  /*
  width: 96vw;
  height: 60vw;
*/
  /* desktop: */


  width: 35vw;
  height: 35vw;


  /*
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  */
}

.tiredColumn {
  margin-left: 7.5vw;
  display: flex;
  flex-direction: row;
  width: 45vw;
}

.freeColumn {
  display: flex;
  flex-direction: row;
  width: 6vw;
}

.restColumn {
  display: flex;
  flex-direction: row;
  width: 45vw;
}

.statusImg1 {
  width: 12.75vw;
  height: 12.75vw;
  grid-column: 1;
  grid-row: 1;
  margin-top: 5vh;
}

.statusImg2 {
  width: 13.5vw;
  height: 13.5vw;
  grid-column: 1;
  grid-row: 2;
  margin-top: 5vh;
}

.statusImg4 {
  width: 12.75vw;
  height: 12.75vw;
  /*grid-column: 1;
  grid-row: 1;*/
  margin-top: 5vh;
}

.statusImg3 {
  width: 12.75vw;
  height: 12.75vw;
  /*grid-column: 3;
  grid-row: 1;*/
  margin-top: 5vh;
}

.statusGreatExplanationDark {
  width: 12.75vw;
  height: 12.75vw;
  font-family: Garamond, serif;
  font-size: 6vw;
  color: var(--text-foreground-dark);
  grid-column: 2;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5vh;
  margin-left: 3vw;
}

.statusGreatExplanationLight {
  width: 12.75vw;
  height: 12.75vw;
  font-family: Garamond, serif;
  font-size: 6vw;
  color: var(--text-foreground-light);
  /*filter: brightness(0.75);*/
  grid-column: 2;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5vh;
  margin-left: 3vw;
}

.statusGoodExplanationDark {
  width: 12.75vw;
  height: 12.75vw;
  font-family: Garamond, serif;
  font-size: 6vw;
  color: var(--text-foreground-dark);
  grid-column: 2;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5vh;
  margin-left: 3vw;
}

.statusGoodExplanationLight {
  width: 12.75vw;
  height: 12.75vw;
  font-family: Garamond, serif;
  font-size: 6vw;
  color: var(--text-foreground-light);
  /*filter: brightness(0.75);*/
  grid-column: 2;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5vh;
  margin-left: 3vw;
}

.statusRestExplanationDark {
  width: 12.75vw;
  height: 12.75vw;
  font-family: Garamond, serif;
  font-size: 6vw;
  color: var(--text-foreground-dark);
  grid-column: 2;
  grid-row: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5vh;
  margin-left: 3vw;
}

.statusRestExplanationLight {
  width: 12.75vw;
  height: 12.75vw;
  font-family: Garamond, serif;
  font-size: 6vw;
  color: var(--text-foreground-light);
  /*filter: brightness(0.75);*/
  /*grid-column: 4;
  grid-row: 1;*/
  /*display: flex;
  flex-direction: column;*/
  justify-content: center;
  margin-top: 6.5vh;
  margin-left: 3vw;
}

.statusTiredExplanationDark {
  width: 12.75vw;
  height: 12.75vw;
  font-family: Garamond, serif;
  font-size: 6vw;
  color: var(--text-foreground-dark);
  /*filter: brightness(0.75);*/
  /*grid-column: 2;
  grid-row: 3;*/
  display: flex;
  /*flex-direction: column;
  justify-content: center;*/
  margin-top: 5vh;
  margin-left: 3vw;
}

.statusTiredExplanationLight {
  width: 12.75vw;
  height: 12.75vw;
  font-family: Garamond, serif;
  font-size: 6vw;
  color: var(--text-foreground-light);
  /*grid-column: 2;
  grid-row: 3;*/
  display: flex;
  /*flex-direction: column;
  justify-content: center;*/
  margin-top: 5vh;
  margin-left: 3vw;
}
</style>