<template>
  <router-view class="app" />
</template>

<script>
import router from "@/main";
import axios from "axios";

export default {
  setup() {
    var initDataBase64 = btoa(window.Telegram.WebApp.initData);

    window.Telegram.WebApp.expand();

    //Dev host:
    //http://localhost:88

    //Server host:
    //https://mygreatday.ru:88

    axios
      .get('https://mygreatday.ru:88/userBase/showExample?initData='+initDataBase64)
      .then(response => {
        if (response.status === 200) {
          if (response.data.showExample) {
            router.push("/examplePage1");
          } else {
            router.push("/statusOfTheDay");
          }
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          router.push("/unathorized");
        } else {
          router.push('/serverErrorPage')
        }
      });
  }
}
</script>

<style>
:root {
  /*--field-foreground-dark: #de9151;*/
  --field-foreground-dark: darkgoldenrod;
  --field-bright-line-dark: #FFCE00;
  --field-substrate-line-dark: #CCCCCC;
  --lines-text-foreground-dark: #2b2b2b;

  --text-foreground-dark: #f9f8f8;
  --button-label-dark: #2b2b2b;
  --button-background-dark: #c2fbd7;

  --field-foreground-light: darkgoldenrod;
  --field-bright-line-light: #FFCE00;
  --field-substrate-line-light: #CCCCCC;
  --lines-text-foreground-light: #39393a;

  --text-foreground-light: #39393a;
  --button-label-light: #39393a;
  --button-background-light: #c2fbd7;
}

.app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Garamond";
  font-display: auto;
  src: url("../fonts/EBGaramond08-Regular.otf");
}

@font-face {
  font-family: "Sonatina";
  font-display: auto;
  src: url("../fonts/Sonatina-script.ttf");
}

@font-face {
  font-family: "Windsor";
  font-display: auto;
  src: url("../fonts/Windsor.ttf");
}
</style>